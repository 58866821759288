import { useI18n } from "vue-i18n";
import type { RootNavItem } from "@/components/Navigation/types";

export const useNavStore = defineStore("navigations", () => {
	const { $sentry } = useNuxtApp();
	const { t } = useI18n();
	const { getJsonDocFilesNamesFile } = useFMApi();
	const { user, isDeveloper, isTengivaUser } = storeToRefs(useUserStore());

	const routes = computed((): RootNavItem[] => [
		{
			id: "dashboard",
			icon: "team_dashboard",
			label: t("components.navigations.label.dashboard"),
			showBadge: false,
			path: "/",
			showOnTabbar: true,
			hidden: false,
		},
		{
			id: "devs",
			icon: "md-integration_instructions",
			label: "Devs",
			items: isDeveloper.value
				? serviceNames.value.map((name: string) => {
						const serviceName = name.replace(".json", "");
						return {
							id: `devs_${serviceName.toLowerCase()}`,
							label: serviceName,
							path: `/api/${serviceName}`,
						};
					})
				: [],
			showOnTabbar: false,
			showBadge: false,
			hidden: !isDeveloper.value,
		},
		{
			id: "kiosk",
			icon: "md-storefront",
			label: "Kiosk",
			path: "/kiosk",
			showOnTabbar: true,
			showBadge: false,
			hidden: false,
		},
		{
			id: "tengiva_admins",
			icon: "md-supervisor_account",
			label: "Tengiva Admin",
			showOnTabbar: false,
			items: [
				{
					id: "organizations",
					label: "Organizations",
					path: "/tengiva-admin/organizations",
				},
				{
					id: "services",
					label: "Services",
					path: "/tengiva-admin/services",
				},
				{
					id: "serviceSubscriptions",
					label: "Services subscriptions",
					path: "/tengiva-admin/service-subscriptions",
				},
				{
					id: "packages",
					label: "Packages",
					path: "/tengiva-admin/packages",
				},
				{
					id: "skus",
					label: "SKUs",
					path: "/tengiva-admin/digitization-skus",
				},
				{
					id: "requests",
					label: "Requests",
					path: "/tengiva-admin/digitization-requests",
				},
				{
					id: "deliveries",
					label: "Delivery",
					path: "/tengiva-admin/digitization-delivery",
				},
				{
					id: "queue",
					label: "Queue",
					path: "/tengiva-admin/digitization-queue",
				},
				{
					id: "workflow",
					label: "Workflow",
					path: "/tengiva-admin/digitization-workflow",
				},
				{
					id: "swatches",
					label: "Swatches",
					path: "/tengiva-admin/digitization-swatches",
				},
				{
					id: "covolt-access",
					label: "Covolt access",
					path: "/tengiva-admin/covolt-access",
				},
				{
					id: "my-team",
					label: "My team",
					path: "/tengiva-admin/my-team",
				},
				{
					id: "translation_management",
					label: t("components.navigations.label.translaion_management"),
					path: "/tengiva-admin/translation-management",
				},
				{
					id: "sample-orders",
					label: t("components.navigations.label.sample_orders"),
					path: "/tengiva-admin/sample-orders",
				},
			],
			hidden: !isTengivaUser.value,
		},
	]);

	const serviceNames = ref<string[]>([]);

	const updateServieNames = async () => {
		try {
			const { response } = await getJsonDocFilesNamesFile();
			if (response?.data) {
				const { main, production } = response!.data as { main: string[]; production: string[] };
				serviceNames.value = main.length >= production.length ? main : production;
			}
		} catch (err: any) {
			$sentry?.captureException(`Can not update service names, ${err.toString()}`);
		}
	};

	watchEffect(async () => {
		if (user.value?.id) {
			await updateServieNames();
		}
	});
	return {
		routes,
	};
});
